<template>
	<div>
		<importView viewType="deviceManagement" :tableHead="tableHead"></importView>
	</div>
</template>

<script>
export default {
	name: 'deviceManagement',
	components: {
		importView: () => import('../carManagement/index.vue'),
	},
	data() {
		return {
			tableHead: [
				{
					label: '设备名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '设备类型',
					prop: 'modelDictionaryItem',
					formatter: (row) => {
						return row.modelDictionaryItem?.title || '-';
					},
				},
				{
					label: '性质',
					prop: 'characterDictionaryItem',
					formatter: (row) => {
						return row.characterDictionaryItem?.title || '-';
					},
				},
				{
					label: '用途',
					prop: 'usageDictionaryItem',
					formatter: (row) => {
						return row.usageDictionaryItem?.title || '-';
					},
				},

				{
					label: '所属单位',
					prop: 'ownerOrganizationName',
					formatter: (row) => {
						return row.ownerOrganizationName || '-';
					},
				},
				{
					label: '负责人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '设备状态',
					prop: 'state',
					formatter: (row) => {
						return row.state === 'I' ? '闲置' : row.state === 'U' ? '使用中' : '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
</style>
